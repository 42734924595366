import * as React from 'react';

type Props = {
  className?: string;
};

const ProServIcon: React.FC<Props> = ({ className }) => (
  <svg
    className={className}
    width="112"
    height="28"
    viewBox="0 0 112 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6_365)">
      <path
        d="M0 14C0 19.7983 2.66629 24.5 5.95745 24.5C6.8643 24.5 7.72217 24.1435 8.49135 23.5044C6.91858 21.0091 5.95745 17.6685 5.95745 14C5.95745 10.3315 6.91858 6.99093 8.49135 4.49426C7.72217 3.85648 6.8643 3.5 5.95745 3.5C2.66629 3.5 0 8.20037 0 14Z"
        fill="#FFF1B0"
      />
      <path
        d="M7.19125 27.8833C8.47277 27.6435 9.65499 27.0343 10.7234 26.1269C13.5723 23.7054 15.4893 19.1852 15.4893 14C15.4893 8.81481 13.5723 4.29463 10.7234 1.87315C9.65499 0.965741 8.47277 0.356481 7.19125 0.116667C7.17913 0.112474 7.16858 0.104777 7.16102 0.0945928C7.15346 0.0844089 7.14923 0.0722176 7.14889 0.0596296C7.14887 0.044254 7.15492 0.029467 7.16578 0.0183559C7.17663 0.0072448 7.19145 0.0006685 7.20714 0L15.4893 0C20.753 0 25.0212 6.26889 25.0212 14C25.0212 21.7311 20.753 28 15.4893 28H7.20714C7.19914 28 7.19122 27.9985 7.18383 27.9955C7.17645 27.9925 7.16973 27.9881 7.16408 27.9825C7.15842 27.977 7.15394 27.9704 7.15088 27.9632C7.14782 27.956 7.14624 27.9482 7.14624 27.9404C7.14677 27.9275 7.15139 27.915 7.15945 27.9048C7.16752 27.8946 7.17863 27.8871 7.19125 27.8833Z"
        fill="#FFD602"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.094 13.4076C45.4889 12.7096 45.6945 11.9241 45.691 11.1261C45.691 10.2187 45.507 9.39296 45.094 8.70982C44.6434 7.98286 43.9891 7.39794 43.2088 7.02463C42.3814 6.6137 41.3699 6.43093 40.1745 6.43093H34.5652V21.5717H37.6458V15.7383H40.1745C41.4613 15.7383 42.4727 15.5556 43.3001 15.0993C44.0637 14.7355 44.6912 14.146 45.094 13.4141V13.4076ZM41.9167 12.7232C41.5023 13.1328 40.9052 13.3156 40.0315 13.3156H37.6458V8.8913H40.0368C41.738 8.8913 42.5654 9.62111 42.5654 11.1261C42.5654 11.8106 42.335 12.3576 41.922 12.7232H41.9167Z"
        fill="#003464"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.8582 13.2093C51.3202 12.7517 52.0589 12.5689 53.028 12.5689H53.8131V9.36963C53.053 9.36135 52.3043 9.55055 51.6432 9.91796C50.9975 10.2454 50.4668 10.7547 50.1194 11.3802V9.50704H47.0269V21.5717H50.1194V15.5854C50.1194 14.442 50.3511 13.6656 50.8582 13.2093Z"
        fill="#003464"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.5629 10.1396C63.5808 9.63392 62.4879 9.36967 61.3783 9.36967C60.2687 9.36967 59.1758 9.63392 58.1937 10.1396C57.2384 10.6319 56.4521 11.3883 55.9325 12.3148C55.3791 13.2222 55.1475 14.3059 55.1475 15.5283C55.1475 16.7507 55.3791 17.8357 55.9325 18.7406C56.4215 19.6731 57.1959 20.4326 58.1474 20.9131C59.11 21.4265 60.1902 21.6915 61.2863 21.6831C62.4114 21.6915 63.5212 21.4269 64.5165 20.9131C65.462 20.4091 66.2451 19.6566 66.7777 18.7406C67.3324 17.8331 67.6091 16.7494 67.6091 15.5283C67.6091 14.3072 67.3774 13.2222 66.8241 12.3148C66.2911 11.399 65.5081 10.6467 64.5629 10.1422V10.1396ZM64.0095 17.4715C63.7641 17.9753 63.3592 18.3881 62.8551 18.6485C62.3766 18.9142 61.8363 19.0544 61.2863 19.0556C60.8795 19.0642 60.4755 18.9878 60.1013 18.8313C59.7271 18.6748 59.3913 18.4419 59.1164 18.1481C58.5167 17.5156 58.24 16.6548 58.24 15.5244C58.24 14.3941 58.5167 13.532 59.1164 12.8994C59.4062 12.6124 59.7512 12.3846 60.1315 12.229C60.5118 12.0735 60.9198 11.9934 61.332 11.9934C61.7441 11.9934 62.1521 12.0735 62.5324 12.229C62.9127 12.3846 63.2577 12.6124 63.5475 12.8994C64.1472 13.532 64.4742 14.3928 64.4742 15.5244C64.4742 16.2931 64.3418 16.927 64.0135 17.4689L64.0095 17.4715Z"
        fill="#003464"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.7942 19.6415C76.1932 20.1393 75.3618 20.4115 74.3437 20.4115C73.188 20.4115 72.3579 20.1393 71.7556 19.5961C71.2009 19.0517 70.8288 18.3724 70.7852 17.6517H69.1661C69.1621 18.4015 69.4054 19.1325 69.8598 19.7361C70.2755 20.37 70.9189 20.8237 71.6629 21.1854C72.5138 21.5244 73.4251 21.694 74.3437 21.6844C75.3618 21.6844 76.2859 21.503 77.0259 21.0946C77.7215 20.7716 78.3014 20.2507 78.69 19.6C79.0901 18.9927 79.2991 18.2836 79.2911 17.5609C79.2911 16.6535 79.0594 15.9302 78.6437 15.3857C78.2414 14.8473 77.6986 14.4246 77.0723 14.162C76.2942 13.8261 75.4898 13.5521 74.6668 13.3428C73.7877 13.1159 73.1403 12.8904 72.632 12.7089C72.1295 12.5181 71.6846 12.206 71.3385 11.8015C71.0142 11.4385 70.8289 10.9407 70.8289 10.3056C70.8158 9.92576 70.8926 9.54815 71.0534 9.20227C71.2142 8.8564 71.4545 8.55164 71.7556 8.31185C72.4258 7.83192 73.2368 7.57766 74.067 7.58722C75.0838 7.58722 75.8702 7.85944 76.4712 8.31185C77.0259 8.81092 77.3979 9.34889 77.488 9.98926H79.1521C79.094 9.46615 78.929 8.95982 78.667 8.5004C78.405 8.04098 78.0513 7.63785 77.627 7.315C76.7942 6.63574 75.6411 6.31815 74.2061 6.31815C73.3191 6.31373 72.4403 6.48388 71.6219 6.81852C70.9207 7.13403 70.3244 7.63623 69.9008 8.26777C69.4885 8.8865 69.2788 9.6137 69.2998 10.3522C69.2998 11.2596 69.4851 12.0283 69.9008 12.5728C70.3628 13.1159 70.8712 13.5243 71.4723 13.7511C72.25 14.0857 73.054 14.3583 73.8764 14.5665C74.7555 14.7933 75.4479 15.0189 75.9576 15.2004C76.4407 15.3797 76.8697 15.6758 77.2046 16.0611C77.5506 16.5194 77.7296 17.0781 77.713 17.6478C77.7273 18.0277 77.651 18.4057 77.4902 18.7518C77.3293 19.0979 77.0883 19.4025 76.7863 19.6415H76.7942Z"
        fill="#003464"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.0096 17.7994H90.409C90.1834 18.5523 89.7199 19.2158 89.0851 19.6946C88.409 20.1243 87.6289 20.3715 86.8238 20.4111C86.0187 20.4507 85.2173 20.2814 84.5005 19.9202C83.8468 19.6001 83.3046 19.0976 82.9423 18.4761C82.5056 17.8028 82.2822 17.018 82.3002 16.2206H92.1565C92.1565 15.9496 92.2015 15.5439 92.2015 15.0539C92.2137 14.0944 91.9773 13.1476 91.5144 12.3019C91.0686 11.4768 90.3825 10.8 89.5432 10.3574C88.6403 9.83509 87.6063 9.5702 86.5578 9.59259C85.4993 9.57743 84.456 9.84066 83.5368 10.3548C82.6656 10.8059 81.9785 11.528 81.4741 12.4289C80.9698 13.3765 80.7407 14.4148 80.7407 15.6333C80.7407 16.8519 80.9698 17.9343 81.4741 18.8365C81.9548 19.7149 82.6881 20.4361 83.5831 20.9106C84.4885 21.4215 85.5168 21.6865 86.5618 21.678C88.0287 21.678 89.2202 21.3167 90.1363 20.5943C91.0755 19.906 91.7384 18.917 92.0096 17.7994ZM83.6215 11.9791C84.3999 11.2575 85.3624 10.8967 86.5089 10.8967C87.2232 10.8846 87.9302 11.0393 88.5715 11.3478C89.1998 11.6658 89.7242 12.1505 90.0847 12.7465C90.4964 13.3778 90.6341 14.0998 90.6341 15.002H82.2936C82.34 13.6941 82.798 12.7011 83.6175 11.9791H83.6215Z"
        fill="#003464"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M96.2618 12.0387C96.9463 11.4489 97.8121 11.1313 98.9096 11.1313H99.32V9.59259C98.3165 9.59259 97.4957 9.77537 96.8046 10.1837C96.13 10.5841 95.5908 11.1702 95.2543 11.8689V9.77018H93.7544V21.5665H95.2583V14.9891C95.2583 13.5826 95.6237 12.6298 96.2618 12.0387Z"
        fill="#003464"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M106.337 20.1081L102.262 9.69759H100.652L105.459 21.5717H107.172L112 9.69759H110.349L106.337 20.1081Z"
        fill="#003464"
      />
    </g>
    <defs>
      <clipPath id="clip0_6_365">
        <rect width="112" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ProServIcon;
